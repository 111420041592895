.triptico-container {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  // padding-bottom: 3rem;
  .triptico--img {
    // padding: 1rem;
  }
  .triptico--title {
    font-size: 1.2rem;
    text-align: right;
    margin-right: .5rem;
  }
}

@media(min-width: 1300px) {
  .triptico-container {
    // .triptico--img {
    //   padding-left: 3rem;
    //   padding-right: 3rem;
    // }
    .triptico--title {
      font-size: 1.5rem;
      font-weight: 500;
      margin-top: -.5rem;
      margin-bottom: -.5rem;
      margin-right: 1rem;
    }
  }
}