.ck-switch-options {
  display: inline-flex;
  flex-wrap: wrap;
  border-radius: 50em;
  border: 1px solid #e6e6e6;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
}
.ck-switch-options__item {
  cursor: pointer;
  color: #757575;
  padding: 0.83333em 34px;
  /* margin: -1px -2px -1px; */
  border-radius: 50em;
  font-size: .88889em;
  font-weight: 600;
  transition: all 0.45s cubic-bezier(0, 0, 0.2, 1);
  list-style-type: none;
}
.ck-switch-options__item.is-selected {
  background-color: var(--color-primary);
  color: white;
}