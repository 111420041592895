.copyright__container {
  display: flex;
  align-items: center;
  .copyright__text {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    .copyright {
      white-space: nowrap;
    }
    .developed-by {
      padding-left: .25rem;
      white-space: nowrap;
    }
  }
}