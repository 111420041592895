.contact_methods-container {
  // display: flex;
  // align-items: center;
  .contact_method-container {
    padding-top: 3.5rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    // padding-right: 8rem; // align to title
    .contact_method--icon {
      width: 80px;
      height: 80px;
      padding-bottom: 1rem;
      transition: .2s;
      &:hover {
        filter: invert(.55) sepia(1) saturate(5) hue-rotate(315deg);
      }
    }
    .contact_method--value {
      font-size: 1.2rem;
      text-align: center;
      width: 100%;
      &:hover {
        color : var(--color-primary);
      }
    }
  }
}

@media(max-width: 991px) {
  .contact-me__title {
    text-align: center;
  }
  .contact_methods-container {
    .contact_method-container {
      padding-right: 0;
    }
  }
  .contact-me__img-container {
    display: none;
  }
}